import _, { isNull, isUndefined } from 'lodash';

import api from 'api';
import apiAxios from 'api-axios';
import base64toPDF from 'lib/base64ToPDF';
import { actions as layoutActions } from 'core/Layout/Layout.redux';
import { message } from 'antd';

const DEFAULT_MARGIN = 38; // margin, orderMargin

let typesRaw = {
	SET_STATE: 'SET_STATE',
	SET_SUBSTATE: 'SET_SUBSTATE',
	RESET_ALL: 'RESET_ALL',
	RESET_LINES: 'RESET_LINES',
	RESET_BP: 'RESET_BP',
	SET_HEADER_INPUT: 'SET_HEADER_INPUT',
	SET_HEADER_VALUE: 'SET_HEADER_VALUE',
	SET_BP_VALUE: 'SET_BP_VALUE',
	SET_LINE_VALUE: 'SET_LINE_VALUE',
	SET_LINE_DATA: 'SET_LINE_DATA',
	SET_LINE_AMOUNT: 'SET_LINE_AMOUNT',
	DELETE_LINE: 'DELETE_LINE',
	DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
	ADD_LINE: 'ADD_LINE',
	ADD_LINE_MULTI: 'ADD_LINE_MULTI',
	SET_VENDORS: 'SET_VENDORS',
	SET_SEARCH_OPTIONS: 'SET_SEARCH_OPTIONS',
	SET_SEARCH_STRINGS: 'SET_SEARCH_STRINGS',
};


export const types = Object.keys(typesRaw).reduce((all, key) => ({
	...all,
	[key]: `ORDER_ENTRY/${typesRaw[ key ]}`,
}), {});


const defaultExtras = {
		vendors: [],
	},

	defaultLineExtras = {
		bom_children: [],
	},

	emptyLine = {
		row_index: 0,
		sku: '',
		description: '',
		hide_from_pdf: 'N',
		quantity: 0,
		open_quantity: 0,
		unit_price: 0,
		unit_cost: 0,
		margin: DEFAULT_MARGIN,
		model_id: null,
		model_code: '',
		is_child: false,
		line_id: null,
		sap_line_id: null,
		line_status: 'O',
		sku_use_bom: false,
		vendor_code: '',
		vendor_name: '',
		extras: {...defaultLineExtras },
	};




const initialState = {
	loading: false,
	saving: false,
	saveSuccess: false,
	uploading_files: false,
	isDupe: false,

	order_id: null,
	order_foreign_id: null,
	order_foreign_number: null,

	quote_id: null,
	quote_foreign_id: null,

	states: {},
	countries: [],
	sales_persons: [],
	employees: [],

	searchOptions: {
		page: 0,
		limit: 10,
		status: '',
		type: 'order',
	},
	searchStrings: {
		docnum: '',
		customer: '',
		owner: '',
		salesperson: '',
		project: '',
	},

	show_modal_bp_create: false,
	show_modal_bp_search: false,
	bp_search_value: '',
	selected_bp: {
		bp_id: null,
		bp_name: null,
		primary_phone: null,
		email: null,
		contact_name: null,
		customer_payment_terms: null,
		notes: null,
	},
	selected_contact: {
		contact_name: null,
		first_name: null,
		last_name: null,
		primary_phone: null,
		email: null,
	},
	selected_shipping_address: {
		address_name: null,
		address_attn: null,
		address_line_1: null,
		address_line_2: null,
		address_city: null,
		address_state: null,
		address_country: null,
		address_zip: null,
	},
	selected_billing_address: {
		address_name: null,
		address_attn: null,
		address_line_1: null,
		address_line_2: null,
		address_city: null,
		address_state: null,
		address_country: null,
		address_zip: null,
	},

	header_edit_field: null,
	header_edit_value: null,
	header: {
		bp_id: null,
		contact: null,
		shipping_address: null,
		billing_address: null,
		event_date: null,
		due_date: null,
		preferred_vendor: null,
		remarks: '',
		production_notes: '',
		order_notes: '',
		internal_notes: '',
		priority: 'normal',
		document_type: 'order',
		sales_person: -1,
		document_owner_id: null,
		project_name: '',
		margin: DEFAULT_MARGIN,
		reference_number: '',
		document_total: null,
		order_from_quote: false,
		customer_payment_terms: null,
		last_po: null,
		tracking_number:'',
		orginalTrackingNum: '', // used to check tracking updates
		order_status:'',
		dp_invoice_docNum: null,
	},

	isNetCustomer: null,
	order_extras: {...defaultExtras },

	item_search_source: 'sap',

	lines: [{
		...emptyLine,
	} ],

	deleted_lines: [],

	attachments: [],

	deleted_attachments: [],

	show_modal_order_save: false,
	save_response: {
		order_sync_messages: [],
		item_sync_messages: [],
		error_messages: [],
		errors: [],
	},
};




export const reducers = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_STATE:
		{
			return {...state, ...action.data };
		}



		case types.SET_SUBSTATE:
		{
			return {
				...state,
				[action.property]: {
					...state[action.property],
					...action.data,
				},
			};
		}


		case types.SET_SEARCH_OPTIONS:
			return {
			  ...state,
			  searchOptions: action.data,
			};

		case types.SET_SEARCH_STRINGS:
			return {
				...state,
				searchStrings: action.data,
			};

		case types.RESET_ALL:
		{
			return {
				...initialState,
				searchOptions: state.searchOptions,
				searchStrings: state.searchStrings,
				lines: [initialState.lines[0]],
				order_extras: { ...initialState.order_extras },
			 };
		}


		case types.RESET_LINES:
		{
			return {
				...state,
				lines: [
					{
						...emptyLine,
					},
				],
				order_extras: { ...defaultExtras  },
			};
		}


		case types.RESET_BP:
		{
			return {
				...state,
				header: {...initialState.header },
				selected_bp: {...initialState.selected_bp },
				selected_contact: {...initialState.selected_contact },
				selected_shipping_address: {...initialState.selected_shipping_address },
				selected_billing_address: {...initialState.selected_billing_address },
			};
		}


		case types.SET_HEADER_INPUT:
		{
			return {
				...state,
				header_edit_field: action.field,
				header_edit_value: action.value,
			};
		}


		case types.SET_HEADER_VALUE:
		{
			return {
				...state,
				header: {
					...state.header,
					...action.data,
				},
			};
		}

		case types.SET_BP_VALUE:
		{
			return {
				...state,
				selected_bp: {
					...state.selected_bp,
					...action.data,
				},
			};
		}

		case types.SET_LINE_VALUE:
		{
			return {
				...state,
				lines: [
					...state.lines.slice(0, action.index),
					{
						...state.lines[action.index],
						[action.property]: action.value,
					},
					...state.lines.slice(action.index + 1),
				],
			};
		}



		case types.SET_LINE_DATA:
		{
			return {
				...state,
				lines: [
					...state.lines.slice(0, action.index),
					{
						...state.lines[action.index],
						...action.data,
					},
					...state.lines.slice(action.index + 1),
				],
			};
		}


		case types.SET_LINE_AMOUNT:
		{
			return {
				...state,
				lines: [
					...state.lines.slice(0, action.index),
					{
						...state.lines[action.index],
						...calculateLineAmounts(state.lines[action.index], action.property, action.value),
					},
					...state.lines.slice(action.index + 1),
				],
			};
		}


		case types.DELETE_LINE:
		{
			return {
				...state,
				lines: state.lines
					.filter((line, index) => index !== action.index)
					.map((line, index) => ({...line, row_index: index })),
				deleted_lines: [
					...state.deleted_lines,
					...((state.lines[action.index].line_id === null && state.lines[action.index].sap_line_id === null) ?
						[] :
						[{
							line_id: state.lines[action.index].line_id,
							sap_line_id: state.lines[action.index].sap_line_id,
							row_index: state.lines[action.index].row_index,
						} ]
					),
				],
			};
		}

		case types.DELETE_ATTACHMENT:
			{
				//console.log(state.attachments[action.index]);
				return {
					...state,
					attachments: state.attachments
						.filter((attachment, index) => index !== action.index)
						.map((attachment, index) => ({...attachment,  attachment_line: attachment.attachment_line !== 0 ? index + 1 : index})),
						deleted_attachments: [
						...state.deleted_attachments,
						...[{
							attachment_id: state.attachments[action.index].attachment_id,
							portal_id: state.attachments[action.index].portal_id,
							attachment_line: state.attachments[action.index].attachment_line,
						}],
					],
				};
			}


		case types.ADD_LINE:
		{
			return {
				...state,
				lines: [
					...state.lines,
					{
						...emptyLine,
						margin: state.header.margin,
						row_index: state.lines.length,
					},
				],
			};
		}


		case types.ADD_LINE_MULTI:
		{
			return {
				...state,
				lines: [
					...state.lines.slice(0, action.fromIndex),
					...action.lines.map((line, index) => ({
						...emptyLine,
						margin: state.header.margin,
						...line,
					})),
					...state.lines.slice(action.fromIndex + 1),
				].map((line, index) => ({...line, row_index: index })),
			};
		}


		case types.SET_VENDORS:
		{
			return {
				...state,
				order_extras: {
					...state.order_extras,
					vendors: action.vendors,
				},
			};
		}


		default:
			return state;
	}
};

export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	setSubState: (property, data) => ({ type: types.SET_SUBSTATE, property, data }),


	setSearchOptions: (data) => ({ type: types.SET_SEARCH_OPTIONS, data }),

	setSearchStrings: (data) => ({ type: types.SET_SEARCH_STRINGS, data }),


	reset_all: () => ({ type: types.RESET_ALL }),

	reset_lines: () => ({ type: types.RESET_LINES }),

	reset_bp: () => ({ type: types.RESET_BP }),


	set_header_focus: (field, active, updateOnBlur = true) => (dispatch, getState) => {
		let state = getState().orders,
			currentValue = state.header_edit_value;

		// Bail out on extra focus events
		if (active && state.header_edit_field === field) {
			return;
		}
		// Bail out on extra blur events
		if (!active && state.header_edit_field === null) {
			return;
		}

		if (active) {
			dispatch({
				type: types.SET_STATE,
				data: {
					header_edit_field: field,
					header_edit_value: state.header[field],
				},
			});
		} else {
			let previousValue = state.header[field],
				headerUpdate = {};

			if (currentValue !== previousValue && updateOnBlur) {
				headerUpdate.header = {...state.header, [field]: currentValue };
			}

			dispatch({
				type: types.SET_STATE,
				data: {
					header_edit_field: null,
					header_edit_value: null,
					...headerUpdate,
				},
			});

		}

		return { previous: state.header[field], current: currentValue };
	},


	set_header_input: (field, value) => ({ type: types.SET_HEADER_INPUT, field, value }),


	set_header_value: (data) => ({ type: types.SET_HEADER_VALUE, data }),

	set_bp_value: (data) => ({ type: types.SET_BP_VALUE, data }),

	set_line_value: (index, property, value) => ({ type: types.SET_LINE_VALUE, index, property, value }),


	set_line_data: (index, data) => ({ type: types.SET_LINE_DATA, index, data }),


	set_line_amount: (index, property, value) => ({ type: types.SET_LINE_AMOUNT, index, property, value}),

	delete_line: (index) => ({ type: types.DELETE_LINE, index }),

	delete_attachment: (index) => ({ type: types.DELETE_ATTACHMENT, index }),

	add_line: () => ({ type: types.ADD_LINE }),

	duplicate_order: (docType, docID) => async (dispatch, getState) => {
		console.log('[OrderEntry.redux] duplicate_order - docID : ' + docID);
		const state = getState().orders;

		dispatch(actions.setState({ loading: true }));

		try {
			let result = await api.get('/orders/order', {
				params: {
					order_id: docID,
					quote_id: null,
					document_type: docType,
				},
			});

			let {
				lines,
				bp_name,
				contact_name,
				order_id,
				selected_bp,
				selected_shipping_address,
				selected_billing_address,
				order_extras,
				attachments, //can be removed?
				...header
			} = result.order;


			let update = {
				header: {
					...state.header,
					contact: contact_name,
					...header,
					remarks: '',
					tracking_number: '',
					event_date: null,
					due_date: null,
					reference_number: '',
					dp_invoice_docNum: null,
				},
				lines: lines.map(line => ({
					...line,
					line_status: 'O',
					sap_line_id: null,
					line_id: null
				})),
				selected_bp: {
					...state.selected_bp,
					...selected_bp,
					bp_name: bp_name,
				},
				selected_contact: {
					...state.selected_contact,
					contact_name: contact_name,
				},
				selected_shipping_address: {
					...state.selected_shipping_address,
					...selected_shipping_address,
				},
				selected_billing_address: {
					...state.selected_shipping_address,
					...selected_billing_address,
				},
				order_extras: {
					...defaultExtras,
					...order_extras,
				},
				attachments: attachments,
				isDupe: true,
			};

			dispatch(actions.setState(update));

		} catch (err) {
			console.error('[OrderEntry.redux] duplicate_order catch err');
			console.error(err);
		}

		dispatch(actions.setState({ loading: false }));
	},

	add_line_multi: (lines = [], fromIndex) => async(dispatch) => {

		let linesWithBOMs = [...lines];

		// Fetch BOMs for each line passed in
		for (let i = 0; i < lines.length; i++) {
			const line = lines[i];

			try {
				const result = await api.get('/configurator/bom/components', {
					params: {
						sku: line.sku,
						source: 'configurator',
					},
				});

				linesWithBOMs[i] = {
					...line,
					extras: {
						...defaultLineExtras,
						bom_children: result.components?.map((row, index) => ({...row, row_index: index })) ?? [],
					},
				};
			} catch (err) {
				console.error(err);
			}
		}


		dispatch({ type: types.ADD_LINE_MULTI, lines: linesWithBOMs, fromIndex });
	},


	load_required_data: () => async(dispatch) => {
		dispatch(actions.setState({ loading: true }));

		try {
			let [locations, salesPersons, employees] = await Promise.all([
				api.get('/bp/locations'),
				api.get('/sap/sales-persons'),
				api.get('/sap/employees'), 
			]);

			let { countries, states } = locations;


			dispatch(actions.setState({
				countries,
				states,
				sales_persons: salesPersons.sales_persons,
				employees: employees.employees,
			}));
		} catch (err) {
			console.error(err);
		}
	},


	create_order_from_quote: () => async (dispatch, getState) => {
		let state   = getState().orders,
			orderID = state.order_id,
			sapID   = state.order_foreign_id;

		await new Promise((resolve, reject) => {
			// preserve the id's from the quote document as we'll need them
			dispatch(actions.setState({ quote_id: orderID }));
			dispatch(actions.setState({ quote_foreign_id: sapID }));

			// clear document id's so SAP will see this as a new order
			dispatch(actions.setState({ order_id: null }));
			dispatch(actions.setState({ order_foreign_id: null }));
			dispatch(actions.setState({ order_foreign_number: null }));
			dispatch(actions.set_header_value({ order_id: null }));
			dispatch(actions.set_header_value({ sap_id: null }));
			dispatch(actions.set_header_value({ sap_number: null }));
			dispatch(actions.set_header_value({ document_type: 'order' }));

			// add another state flag to let the api know we're going to add the fields to the lines
			// necessary to link the quote to the order
			dispatch(actions.set_header_value({ order_from_quote: true }));

			return resolve(true);
		});

	},


	// files, setFiles = new attachments to upload and handler to clear them out
	save_order: (files, setFiles, mode) => async(dispatch, getState) => { // TODO mode not used

		console.log('[OrderEntry.redux] save_order');

        let state       = getState().orders,
            lines       = state.lines,
            header      = state.header,
            orderID     = state.order_id,
            sapID       = state.order_foreign_id,
            quoteID     = state.quote_id,
            quoteSapID  = state.quote_foreign_id,
            isDupe      = state.isDupe,
            deleted_attachments = state.deleted_attachments,
            isValid     = checkRequiredInfo(header),
			resultSapId,
			resultorderID,
			updateTracking;

			let objectType = header.document_type === 'order'
			? 'sales_order'
			: 'sales_quote';
        
		if (!isValid) {
            message.error('Fields in order header marked with a * are required');
            return;
        }

	/******************
	 *   LINE VALIDATION   *
	 ******************/
	let errorsLines = [];
	lines.forEach(line => {
		//console.log(line);

		console.log(line.margin);
		console.log(typeof line.margin);
		let _margin = parseFloat(line.margin);
		console.log(_margin);

		if(Number.isNaN(_margin)) {
			errorsLines.push(`Margin is not a number for line ${line.row_index + 1}`);
		}

		if(!line.quantity) {
			errorsLines.push(`Quantity is required for line ${line.row_index + 1}`);
		}

		if(!line.sku) {
			errorsLines.push(`SKU is required for line ${line.row_index + 1}`);
		}
	});

	if(!lines.length) {
		errorsLines.push('Orders require at least one line.');
	}

	if(errorsLines.length) {
		message.error(`Error saving order: ${errorsLines}`);
		console.log('ERRORS', errorsLines);
		return;
	}

	/******************
	 * END VALIDATION *
	 ******************/
		// we cant compare tracking number to see if its updated because the state would already change.
		console.log('header.orginalTrackingNum', header.orginalTrackingNum)
		console.log('header.tracking_number', header.tracking_number)
		if (header.orginalTrackingNum !== header.tracking_number){
			console.log('tracking number does not match');
			updateTracking = 'Yes';
		} else {
			console.log('tracking number are the same');
		}
        const saveOrder = async () => {
            let dupePayload = {
            order: {
                order_id: null,
                document_owner_id: state.header.document_owner_id,
                contact: state.header.contact,
                shipping_address: state.header.shipping_address,
                billing_address: state.header.billing_address,
                customer_payment_terms: state.header.customer_payment_terms,
                document_type: state.header.document_type,
                due_date: state.header.due_date,
                event_date: state.header.event_date,
                internal_notes: state.header.internal_notes,
				order_notes: state.header.order_notes,
                margin: state.header.margin,
                order_from_quote: state.header.order_from_quote,
                preferred_vendor: state.header.preferred_vendor,
                priority: state.header.priority,
                production_notes: state.header.production_notes,
                project_name: state.header.project_name,
                reference_number: state.header.reference_number,
                remarks: state.header.remarks,
                sales_person: state.header.sales_person,
                bp_id: state.header.bp_id,
                bp_name: state.selected_bp.bp_name,
				customer_notes: state.selected_bp.notes,
				bp_notes: state.selected_bp.bp_notes,
                lines,
                deleted_lines: state.deleted_lines,
                shipping_address_data: state.selected_shipping_address,
                billing_address_data: state.selected_billing_address,
                order_extras: state.order_extras,
                last_po: state.last_po,
				tracking_number:state.header.tracking_number,
				order_status:state.header.order_status,
            },
        };
        let payload = {
            order: {
                order_id: orderID,
                sap_id: sapID,
                quote_id: quoteID,
                quote_foreign_id: quoteSapID,
                ...header,
                bp_name: state.selected_bp.bp_name,
				customer_notes: state.selected_bp.notes,
				bp_notes: state.selected_bp.bp_notes,
                lines,
                deleted_lines: state.deleted_lines,
                shipping_address_data: state.selected_shipping_address,
                billing_address_data: state.selected_billing_address,
                order_extras: state.order_extras,
				updateTracking: updateTracking,
            },
        };

		dispatch(actions.setState({
            saving: true,
            show_modal_order_save: true,
            save_response: { ...initialState.save_response },
        }));
        
		try {
            let result;
            
			if (isDupe) {
                result = await apiAxios.put('/orders', {...dupePayload });
            } else {
                result = await apiAxios.put('/orders', {...payload });
            }

			//makes sure we get required ids for attachments
			resultSapId = result.sap_id;
			resultorderID = result.order_id;

			dispatch(actions.setState({
                order_id: result.order_id,
                order_foreign_id: result.sap_id,
                order_foreign_number: result.sap_number,
                order_from_quote: false,
                saving: false,
                uploading_files: false,
				deleted_lines: [], //clear out deleted lines so we dont delete twice
                save_response: {
                    ...state.save_response,
                    ...result,
                },
            }));



        } catch (err) {
            console.log(err);
            dispatch(actions.setState({
                saving: false,
                saveSuccess: false,
                uploading_files: false,
                save_response: {
					...state.save_response,
					errors: err.response?.data || [ err.message ],
				}
            }));
        }
    };
	
		const attachment_update = async (objectType, orderID, sapID) => {

			console.log('[OrderEntry.redux] save_order -> saveOrder -> attachment_update');
			console.log('[OrderEntry.redux] deleted_attachments.length : ' + deleted_attachments.length);

			let apiAxios_result;
			let apiAxios_result2;

			if (Array.isArray(deleted_attachments) && deleted_attachments.length) {
				try {
					for (const attachment of deleted_attachments) {
						const attachmentID = attachment.portal_id;
						console.log('[OrderEntry.redux] attachment_update DELETE ' + attachmentID);
						//deletes from postgres, sets portal_id UDF to null since SAP does not support deletion of attachment objects
						apiAxios_result = await apiAxios.delete(`/configurator/attachments/${objectType}/${sapID}/${attachmentID}/${orderID}`);
						console.log('apiAxios_result');
						console.log(apiAxios_result);
						console.log(`[OrderEntry.redux] Attachment ${attachment.portal_id} deleted successfully.`);
					}
					dispatch(actions.setState({deleted_attachments: []}));

				} catch (err) {
					console.error('[OrderEntry.redux] attachment_update catch err');
					console.error(err);
				}
			}

			if ((Array.isArray(files) && files.length)) {
				try {
					dispatch(actions.setState({
						uploading_files: true,
					}));
					let formData   = new FormData;
					files.forEach(file => formData.append('attachments', file, file.name));
					apiAxios_result2 = await apiAxios.post(`/configurator/attachments/${objectType}/${orderID}`, formData);
					console.log('apiAxios_result2');
					console.log(apiAxios_result2);
					setFiles([]); // TODO is this intended?


				} catch (err) {
					dispatch(actions.setState({
						uploading_files: false,
					}));
					console.error('ERROR UPLOADING FILES:');
					console.error(err);
				}
			}

			//sync attachments so you dont need to do multiple saves to update attachments
			if((Array.isArray(files) && files.length) || (Array.isArray(deleted_attachments) && deleted_attachments.length)){
				try {
					await apiAxios.post(`/orders/attachment-sync/${objectType}/${orderID}`);
				} catch (err) {
					console.error('ERROR UPLOADING Syncing Files:');
					console.error(err);
				}
			}
			
			dispatch(actions.setState({
				uploading_files: false,
			}));

			return Promise.resolve();
		};

		saveOrder().then(async() => {
			try {
				await attachment_update(objectType, resultorderID, resultSapId);

			} catch (e) {
				console.error('Error saving order:', e);
				return;
			}

			//Load the document so data is fresh from SAP
			console.log('[OrderEntry.redux] saveOrder.then load_order'); // BUG watch this because I think resultSapId is null for a moment after saving
			console.assert(resultSapId, 'resultSapId is null');
			dispatch(actions.load_order(header.document_type, resultSapId, quoteID));
			
		}).catch(error => {
			console.error('Error saving attachments:', error);
			console.log(error);
		});
		
    },



	load_order: (docType, docID) => async(dispatch, getState) => {
		console.log('[OrderEntry.redux] load_order');
		console.log(docType);
		console.log(docID);
		dispatch(actions.setState({ loading: true }));
		const state = getState().orders;

		try {
			// QUERY: Order.LoadOrder + Order.LoadLines
			let result = await api.get('/orders/order', { // BUG empty order_id
				params: {
					order_id: docID,
					quote_id: null,
					document_type: docType,
				},
			});

			let {
				lines,
				bp_name,
				bp_notes,
				contact_name,
				order_id,
				selected_bp,
				selected_shipping_address,
				selected_billing_address,
				order_extras,
				attachments,
				...header
			} = result.order;

			// let orderID = result.order.order_id;
			// let purchase_orders = await api.get(`/orders/sap/production/${orderID}`);
			// let prodDocuments = purchase_orders.production.documents;
			// let sapNumber;
			// if (prodDocuments.length > 0) {
			// 	const last_po =  prodDocuments[prodDocuments.length - 1];
			// 	sapNumber = last_po.sap_number;
			// }
			const updatedAttachments = attachments.filter(attachment => attachment.portal_id !== null);

			// customers with 'net' payment terms are not allowed to have dp invoices
			let isNet;
			if(header.customer_payment_terms){
				isNet = header.customer_payment_terms.toLowerCase().includes('net');
			}

			let update = {
				header: {
					...state.header,
					contact: contact_name,
					orginalTrackingNum: header.tracking_number,
					...header,
					// last_po:sapNumber,
				},
				order_id: order_id || null,
				order_foreign_id: header.sap_id || null,
				order_foreign_number: header.sap_number || null,
				lines: lines.map(line => ({...emptyLine, ...line })),
				selected_bp: {
					...state.selected_bp,
					...selected_bp,
					bp_name: bp_name,
					bp_notes: bp_notes,
				},
				selected_contact: {
					...state.selected_contact,
					contact_name: contact_name,
				},
				selected_shipping_address: {
					...state.selected_shipping_address,
					...selected_shipping_address,
				},
				selected_billing_address: {
					...state.selected_shipping_address,
					...selected_billing_address,
				},
				order_extras: {
					...defaultExtras,
					...order_extras,
				},
				attachments: updatedAttachments,
				isNetCustomer: isNet,
			};

			dispatch(actions.setState(update));

			let titleDocType = header.document_type === 'order' ?
				'Order' :
				'Quote';

			if (update.order_foreign_number) {
				dispatch(layoutActions.setTitle(`Orders: SAP ${titleDocType} #${update.order_foreign_number}`));
			}
		} catch (err) {
			console.error(err);
		}

		dispatch(actions.setState({ loading: false }));
	},


	load_pdf_document: (docType, docEntry) => async(dispatch, getState) => {
		const response = await api.get('/orders/order-document', {
			params: {
				order_id: docEntry,
				type: docType,
			},
		});

		let fileData = response.document.FileData;
		fileData = base64toPDF(fileData);

		const fileURL = URL.createObjectURL(fileData);

		if (fileData) {
			window.open(fileURL);
		} else {
			message.error(`Could not load report for document ${docEntry}`);
		}


	},


	vendor_add: (option, lineIndex) => (dispatch, getState) => {
		let { value } = option;

		if (lineIndex !== null) {
			dispatch(actions.set_line_value(lineIndex, 'vendor_code', option.value));
			dispatch(actions.set_line_value(lineIndex, 'vendor_name', option.name));
		} else {
			let vendors = getState().orders.order_extras?.vendors || [];

			let exists = vendors.find(entry => entry.value === value);

			if (exists) {
				message.info(`Vendor ${value} is already in the preferred vendors list.`);
				return false;
			}

			dispatch({ type: types.SET_VENDORS, vendors: [...vendors, {...option }] });
		}

	},


	vendor_remove: (code) => (dispatch, getState) => {
		let vendors = getState().orders.order_extras?.vendors || [];
		dispatch({ type: types.SET_VENDORS, vendors: vendors.filter(vendor => vendor.value !== code) });
	},


	sku_line_copy_data_to_all: (lineIndex, dataType) => (dispatch, getState) => {
		const state      = getState(),
			lines      = state.orders.lines,
			lineToCopy = lines[lineIndex];

		lines.forEach(line => {
			// this is the line we are copying from
			if (line.row_index === lineIndex) {
				return line;
			}

			// match on model code as its the constant at the sku line
			if (line.model_code === lineToCopy.model_code) {
				let data = {};
				console.log('LINE TO COPY', lineToCopy)
				switch (dataType) {
					case 'unit_price':
						data = _.pick(lineToCopy, ['unit_price']);
						break;

					default:
						return;
				}

				dispatch(actions.set_line_data(line.row_index, data));
			}


		});

	},


	child_line_add: (parentIndex, data) => (dispatch, getState) => {
		let extras = getState().orders.lines[parentIndex].extras,
			lines = extras.bom_children || [],
			newLines = [
				...lines,
				{...data },
			].map((row, index) => ({...row, row_index: index }));

		dispatch(actions.set_line_value(parentIndex, 'extras', {
			...extras,
			bom_children: newLines,
		}));
	},


	child_line_delete: (parentIndex, childIndex) => (dispatch, getState) => {
		let extras = getState().orders.lines[parentIndex].extras,
			lines = extras.bom_children || [],
			newLines = [
				...lines.slice(0, childIndex),
				...lines.slice(childIndex + 1),
			].map((row, index) => ({...row, row_index: index }));

		dispatch(actions.set_line_value(parentIndex, 'extras', {
			...extras,
			bom_children: newLines,
		}));
	},


	child_line_duplicate: (parentIndex, childIndex) => (dispatch, getState) => {
		let extras = getState().orders.lines[parentIndex].extras,
			lines = extras.bom_children || [],
			newLines = [
				...lines.slice(0, childIndex + 1),
				{...lines[childIndex], vendor_code: '', vendor_name: '' }, // Clear out vendor, because that's the use case
				...lines.slice(childIndex + 1),
			].map((row, index) => ({...row, row_index: index }));

		dispatch(actions.set_line_value(parentIndex, 'extras', {
			...extras,
			bom_children: newLines,
		}));
	},


	child_line_duplicate_to_all: (parentIndex, childIndex) => (dispatch, getState) => {
		let lines = getState().orders.lines,
			lineToClone = lines[parentIndex].extras?.bom_children[childIndex],
			newLines = lines.map(line => {
				if (line.row_index === parentIndex) {
					return line;
				}

				let bomChildren = line.extras.bom_children || [];

				return ({
					...line,
					extras: {
						...line.extras,
						bom_children: [
							...bomChildren,
							{...lineToClone },
						].map((row, index) => ({...row, row_index: index })),
					},
				});
			});

		dispatch(actions.setState({
			lines: newLines,
		}));
	},


	child_line_update: (parentIndex, childIndex, data) => (dispatch, getState) => {
		let extras = getState().orders.lines[parentIndex].extras,
			lines =  _.has(extras, 'bom_children') ? extras.bom_children : [],
			newLines = [
				...lines.slice(0, childIndex),
				{
					...lines[childIndex],
					...data,
				},
				...lines.slice(childIndex + 1),
			];

		dispatch(actions.set_line_value(parentIndex, 'extras', {
			...extras,
			bom_children: newLines,
		}));
	},


	/**
     * @parentIndex - Finished good line index
     * @dataType - property we are copying - 'vendor', 'unit_cost', 'quantity'
     */
	copy_child_line_data_to_all: (parentIndex, dataType) => (dispatch, getState) => {
		let lines = getState().orders.lines,
			lineToCopy = lines[parentIndex],
			childLines = lineToCopy.extras?.bom_children;

		lines.forEach((line, lineIndex) => {

			if (line.row_index === parentIndex) {
				return line;
			}

			let bomChildren = line.extras.bom_children || [];

			bomChildren.forEach((child, childIndex) => {
				let matchedSkuIndex = _.findIndex(childLines, (line) => (line.sku === child.sku || line.description === child.description));

				let data = {};

				switch (dataType) {
					case 'vendor':
						data = _.pick(childLines[matchedSkuIndex], ['vendor_code', 'vendor_name']);
						break;

					case 'unit_cost':
						data = _.pick(childLines[matchedSkuIndex], ['unit_cost']);
						break;

					case 'quantity':
						data = _.pick(childLines[matchedSkuIndex], ['quantity', 'total_purchase_quantity']);
						break;

					default:
						return;
				}

				dispatch(actions.child_line_update(lineIndex, childIndex, data));
			});


		});
	},


	child_line_toggle_all_po: () => (dispatch, getState) => {
		const state = getState(),
			orderLines = state.orders.lines;

		orderLines.forEach((line, lineIndex) => {
			line.extras?.bom_children?.forEach((childLine, childIndex) => {
				if (!childLine.po_item) {
					childLine.po_item = 'Y';
				}

				let newValue = childLine.po_item === 'Y'
						? 'N'
						: 'Y',
					newData = { 'po_item': newValue };

				dispatch(actions.child_line_update(lineIndex, childIndex, newData));
			});
		});
	},


	get_order_totals: () => (dispatch, getState) => {
		//console.log('[OrderEntry.redux] get_order_totals');
		const state = getState(),
			  lines = state.orders.lines;

		//console.log(lines);
		//console.log(lines.length);

		const totals = {
			totalQuantity: 0,
			nonInventoriedQuantity: 0,
			totalPrice: 0,
			totalCost: 0,
			orderMargin: DEFAULT_MARGIN,
			summary_row_index: lines.length,
		};


		for (let i = 0; i < lines.length; i++) {
			const line = lines[i];
			//console.log(line);

			//  do not include non-inventory item on Total Qty
			if(line.is_inventoried === 'Y'){
				//console.log('is_inventoried Y');
				totals.totalQuantity += line.quantity;
			} else {
				//console.log('is_inventoried N');
				totals.nonInventoriedQuantity += line.quantity;
			}
			
			// TODO_NON_INV_CALCULATIONS do we need to adjust total/cost calculations with changes to the qty?
			totals.totalPrice += parseFloat(line.unit_price) * line.quantity;
			totals.totalCost += parseFloat(line.unit_cost) * line.quantity;
		}

		totals.totalPrice = (totals.totalPrice).toFixed(6);
		totals.totalCost = (totals.totalCost).toFixed(6);
		const margin = (((totals.totalPrice - totals.totalCost) / totals.totalPrice) * 100).toFixed(6); // BUG depending on the number, this can be above 10 characters 
		
		if (margin !== 'NaN') {
			totals.orderMargin = (((totals.totalPrice - totals.totalCost) / totals.totalPrice) * 100).toFixed(6); // BUG depending on the number, this can be above 10 characters 
			dispatch(actions.set_header_value({ margin: totals.orderMargin }));
		}

		// if (margin === 'NaN') {
		// 	totals.orderMargin = (((totals.totalPrice - totals.totalCost) / totals.totalPrice) * 100).toFixed(6);
		// 	dispatch(actions.set_header_value({ margin: totals.orderMargin }));
		// }

		return totals;
	},

	create_dp_invoice: () => async(dispatch, getState) => {
		let state   = getState().orders,
		lines       = state.lines,
		header      = state.header,
		billing     = state.selected_billing_address,
		shipping    = state.selected_shipping_address,
		orderID     = state.order_id,
		sapID       = state.order_foreign_id;
		try {
			dispatch(actions.setState({ loading: true }));

			let result = await api.put('/orders/ar_dp_invoice_create', { 
				lines,
				header,
				billing,
				shipping,
				orderID,
				sapID,
			});

			dispatch(actions.load_order('order', sapID));
			message.info('Down payment invoice created.');
		} catch(err) {
			console.log('Failed to create down payment invoice', err);
			message.error('Failed to create down payment invoice.', err);
		}
		dispatch(actions.setState({ loading: false }));
	},

	send_dp_invoice: () => async(dispatch, getState) => {

		let state         = getState().orders,
		cardCode		  = state.header.bp_id,
		dp_invoice_docNum = state.header.dp_invoice_docNum;

		try {
			dispatch(actions.setState({ loading: true }));
			message.info('Sending out Emails...');

			let result = await api.get('/orders/ar_dp_invoice_send', { 
				params: {
					dp_invoice_docNum: dp_invoice_docNum,
					cardCode: cardCode,
				},
			});

			message.info(result.message);
		} catch(err) {
			console.log('Failed to create down payment invoice', err);
			message.error('Send Email Failed.', err);
		}
		dispatch(actions.setState({ loading: false }));
	},
};


function calculateLineAmounts(line, property, value) {
	if (!line) {
		return;
	}

	let result = {
		unit_price: line.unit_price,
		unit_cost: line.unit_cost,
		margin: line.margin,
	};

	value = value || 0;

	// unit_cost for an item with BOMs needs to be calculated from the total of the BOM items
	// unit_cost must be calculated first because the unit_price depends on this being set.
	if (property === 'unit_cost' && line.extras?.bom_children?.length) {
		let new_unit_cost = 0;

		for (let i = 0; i < line.extras.bom_children.length; i++) {
			const child = line.extras.bom_children[ i ];
			if (child.unit_cost) {
				new_unit_cost += Number((child.unit_cost * child.quantity).toFixed(6));
			}
		}

		result.unit_cost = new_unit_cost;
	} else {
		result[property] = value;
	}

	// Set the margin if unit price was entered and there's a unit cost
	if (property === 'unit_price' && value > 0) {
		result.margin = Number(((value - line.unit_cost) / value) * 100).toFixed(6);
	}

	// Set the margin if unit cost is entered and there's a unit price
	if (property === 'unit_cost' && line.unit_price > 0) {
		result.margin = Number(((line.unit_price - value) / line.unit_price) * 100).toFixed(6);
	}

	//Leaving this here for now just in case they want margin to be 0 if theres no selling price
	// // Set the margin if unit cost is entered and there's no unit price
	// if (property === 'unit_cost' && line.unit_price === 0) {
	// 	result.margin = 0 
	// }
	
	// Leaving this comment out for now, they requested when tabbing out of Selling Price,
	// the price stays as entered, Margin% does not adjust the price.
	//Set the unit price if margin is entered and value and unit cost is greater than 0
	// if (property === 'margin' && line.unit_cost > 0 && value > 0) {
	// 	result.unit_price = Number(line.unit_cost / (1 - (value / 100))).toFixed(6);
	// }

	return result;

}


function checkRequiredInfo(orderHeader) {
	let valid = true;
	const required = [
		'bp_id',
		'contact',
		'shipping_address',
		'billing_address',
		'due_date',
		'margin',
		'priority',
	];

	for (let i = 0; i < required.length; i++) {
		const value = required[ i ];

		if (orderHeader[value] === '' || isNull(orderHeader[value]) || isUndefined(orderHeader[value])) {
			valid = false;
		}
	}

	return valid;
}
