import 'plugins';

import React from 'react';
import Router from './Router';
import { Provider } from 'react-redux';
import store from 'core/store';
import 'styles/overrides.less';
import 'styles/App.sass';

const App = () => (
	<Provider store={store}>

		<Router />

	</Provider>
);


export default App;
